import React from "react";

import { Product } from "../../../types/Product";
import ProductPicture from "../../features/Product/ProductPicture.web";
import DiscountablePrice from "../DiscountablePrice";
import DiscountPercentBadge, {
  Props as BadgeProps,
} from "../DiscountPercentBadge";

interface Props {
  product: Product;
  isOutOfStock: boolean;
}

// resolution at which image will resized
const IMAGE_SIZE = 300;

export default function CatalogueListItem(props: Props) {
  const { product, isOutOfStock } = props;
  const { name, price, price_not_discounted, currency, images = [] } = product;

  const pictureStyles = `w-44 h-44 rounded-lg overflow-hidden relative ${
    isOutOfStock && "opacity-30"
  }`;

  const productNameStyles = `text-sm font-medium line-clamp-2 overflow-ellipsis m-0 h-10 ${
    isOutOfStock ? "text-gray-400" : "text-gray-500"
  }`;

  const priceStyles = `text-sm font-bold ${
    isOutOfStock ? "text-gray-400" : "text-gray-900"
  }`;

  return (
    <div className={"flex flex-col justify-center"}>
      <div className={pictureStyles}>
        <ProductPicture name={name} image={images[0]} imageSize={IMAGE_SIZE} />
        <DiscountedPercentBanner
          price={price}
          priceNotDiscounted={price_not_discounted}
          fontSize={8}
        />
      </div>
      {/* add h-16 to name and price to account for 2 line names */}
      <div className={"flex flex-col py-2 w-44 justify-start"}>
        <p
          data-testid={`cataloguelist-item-name`}
          className={productNameStyles}
        >
          {name}
        </p>
        <DiscountablePrice
          price={price}
          priceNotDiscounted={price_not_discounted}
          currency={currency}
          isDisabled={isOutOfStock}
          classNames="text-sm font-bold"
        />
      </div>
    </div>
  );
}

function DiscountedPercentBanner(props: BadgeProps) {
  if (!props.priceNotDiscounted) return null;

  return (
    <div className="absolute flex w-full bottom-0 justify-between items-center bg-black bg-opacity-80">
      <span
        className="text-white-100 m-1 font-semibold"
        style={{ fontSize: props.fontSize }}
      >
        ¡EN OFERTA!
      </span>

      <DiscountPercentBadge {...props} />
    </div>
  );
}
