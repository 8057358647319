import React from "react";

import { mdiCartPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { View } from "react-native";

import ListItem from "../../../components/List/ListItem";
import Modal, {
  Header as ModalHeader,
  Body as ModalBody,
  Footer as ModalFooter,
} from "../../../components/Modal";
import Colors from "../../../constants/Colors";
import Copy from "../../../constants/Copy";
import Layout from "../../../constants/Layout";
import { amountAsCurrency } from "../../../services/currency";
import { tailwind } from "../../../tailwind";
import { Product } from "../../../types/Product";
import Button from "../Button.web";
import ProductVariantPicker from "./ProductVariantPicker.web";

interface Props {
  visible: boolean;
  productGroup: Product;
  products: Product[];
  variants: Product[];
  filters: [];
  filtered: Product[];
  showVariantError: boolean;
  storeClosed: boolean;
  setShowVariantError: Function;
  disabledButtonLabel?: string;
  dispatch: Function;
  handleAddToCart: Function;
  handleRequestClose: () => void;
  title?: string;
}

const ProductGroupModalContent = (props: Props) => {
  const {
    visible,
    productGroup,
    products,
    variants,
    filters,
    filtered,
    showVariantError,
    setShowVariantError,
    disabledButtonLabel = "AGOTADO",
    dispatch,
    storeClosed,
    handleAddToCart,
    handleRequestClose,
    title = null,
  } = props;
  const { name, price, currency, images = [] } = productGroup;
  const isButtonActive = filtered.length > 0 && !storeClosed;

  return (
    <Modal visible={visible} onRequestClose={handleRequestClose}>
      <ModalHeader>
        <div>
          {title && (
            <span className="text-lg font-sans font-medium text-gray-900">
              {title}
            </span>
          )}
          <div className="mt-5">
            <ListItem
              small
              hasImageBorderRadius={true}
              hasRoundImage={false}
              image={images[0]}
              left={
                <span className="text-base font-bold font-sans text-gray-900 pr-2">
                  1x
                </span>
              }
              primary={name}
              secondary={
                <span className="text-sm text-gray-900 font-sans	font-bold">
                  {amountAsCurrency({ amount: price, currency })}
                </span>
              }
              hasMargin={false}
              hasBorder={false}
              height={Layout.vSize(60)}
            />
          </div>
        </div>
      </ModalHeader>

      <ModalBody>
        <ProductVariantPicker
          variants={variants}
          products={products}
          value={filters}
          showVariantError={showVariantError}
          onChange={(key, value) => {
            if (key && value) {
              dispatch({
                type: "filter",
                payload: { [key]: value },
              });
            }
          }}
        />
      </ModalBody>

      <ModalFooter>
        <View
          style={tailwind(
            "border-t border-white-300 bg-white p-4 shadow-reverse"
          )}
        >
          <Button
            testID={`product-group-modal-confirm`}
            disabled={!isButtonActive}
            icon={isButtonActive ? <Icon path={mdiCartPlus} /> : undefined}
            text={
              isButtonActive
                ? Copy.t("catalogue.addToCart")
                : disabledButtonLabel
            }
            onClick={() => {
              setShowVariantError(filtered.length !== 1);

              if (filtered.length === 1) {
                handleAddToCart(filtered[0]);
                handleRequestClose();
              }
            }}
          />
        </View>
      </ModalFooter>
    </Modal>
  );
};

export default ProductGroupModalContent;
