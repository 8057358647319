import React, { useCallback, useState } from "react";

import isEmpty from "lodash/isEmpty";
import { ActivityIndicator } from "react-native";
import { useLockBodyScroll } from "react-use";

import { Category } from "cuanto/types/Category";
import { Product } from "cuanto/types/Product";

import BottomButton from "../../../components/tailwind/BottomButton";
import CataloguePayButtonToast from "../../../features/Catalogue/components/shared/CataloguePayButtonToast";
import { useThrottleFn } from "../../../hooks/useThrottleFn";
import CatalogueHeader from "../CatalogueHeader";
import CatalogueSocialProofWrapper from "../CatalogueSocialProofWrapper";
import CategoriesPills from "../CategoriesPills.web";
import CatalogueBio from "../StorePersonalization/CatalogueBio";
import StoreLinks from "../StorePersonalization/StoreLinks";
import { StoreConfig } from "../StorePersonalization/types";
import CatalogueBanner from "./CatalogueBanner";
import CatalogueFooter from "./CatalogueFooter";
import ProductGroupModal from "./ProductGroupModal.web";
import ProductsList from "./ProductsList";

const TIMEOUT = 5000;

export interface Props {
  storeConfig: StoreConfig;
  hasStoreConfigEnabled: boolean;
  category_uuid: string;
  storeClosed: boolean;
  filteredProducts: Product[];
  categories: Category[];
  productGroups: { [key: string]: Product[] };

  total: number;
  quantity: number;
  transactionCountPastThreeMonths: number;

  isLoadingInventory: boolean;
  numOfCatalogueColumns: number;

  handleSelectProduct: (product: Product) => void;
  handleAddToCart: (product: Product) => void;
  handlePayButtonPress: () => Promise<any>;

  handleGoToFulfillments: () => void;
  handleOpenWhatsapp: () => void;
  handleGoToReviews: () => void;
  handleChangeCategory: (uuid: string) => void;
}

export default function Catalogue(props: Props) {
  const [toast, setToast] = useState("");
  const [selectedProductGroup, setSelectedProductGroup] = useState(null);
  const [locked, toggleLocked] = useState(false);

  useLockBodyScroll(locked);

  const showToast = useThrottleFn(
    useCallback(() => {
      setToast("Producto agregado a tu carrito");
      setTimeout(() => setToast(""), TIMEOUT);
    }, []),
    TIMEOUT,
    { trailing: false }
  );

  const {
    filteredProducts,
    storeClosed,
    categories,
    category_uuid: selectedCategoryUuid,
    numOfCatalogueColumns,
  } = props;
  const { total, quantity } = props;
  const { isLoadingInventory: isLoading } = props;
  const hasTotal = !!total;
  const productModalVisible = !isEmpty(selectedProductGroup);
  const payButtonIsActive = hasTotal && !isLoading && !props.storeClosed;

  const handleAddToCart = (product: Product) => {
    if (props.storeClosed) return;
    showToast();
    props.handleAddToCart(product);
  };

  // we only show the bottom button when the variants modal is hidden
  // as well as when the consumer has something in the cart
  const isButtonVisible = !productModalVisible && quantity > 0;

  return (
    <>
      <CataloguePayButtonToast toast={toast} />
      <ProductGroupModal
        visible={productModalVisible}
        productGroup={selectedProductGroup}
        productVariants={
          selectedProductGroup
            ? props.productGroups[selectedProductGroup.uuid]
            : []
        }
        storeClosed={storeClosed}
        handleAddToCart={handleAddToCart}
        handleRequestClose={() => {
          toggleLocked(false);
          setSelectedProductGroup(null);
        }}
      />
      {props.hasStoreConfigEnabled && (
        <CatalogueBanner bannerUrl={props.storeConfig.banner_image_url} />
      )}
      <div className="m-4">
        <CatalogueHeader />
        <div className="mt-3">
          <StoreLinks links={props.storeConfig.links} />
        </div>
        <CatalogueBio bio={props.storeConfig.bio} />
      </div>
      {!isEmpty(categories) && (
        <div className="pl-4">
          <CategoriesPills
            categories={categories}
            selectedCategoryUuid={selectedCategoryUuid}
            onCategoryClick={props.handleChangeCategory}
            resetSelectedCategoryPillVisbile
          />
        </div>
      )}

      <ProductsList
        storeClosed={props.storeClosed}
        products={filteredProducts}
        productGroups={props.productGroups}
        numColumns={numOfCatalogueColumns}
        handleSelect={props.handleSelectProduct}
        handleAddToCart={(product) => {
          if (props.productGroups[product.uuid]) {
            toggleLocked(true);
            setSelectedProductGroup(product);
          } else {
            handleAddToCart(product);
          }
        }}
      />
      <CatalogueFooter />
      {/* TODO: social proof should only appear once, avoid animation on re-render */}
      {/* Hide SocialProof while switching to a Category */}
      {isEmpty(selectedCategoryUuid) && (
        <CatalogueSocialProofWrapper
          transactionCount={props.transactionCountPastThreeMonths}
          cartQuantity={quantity}
        />
      )}
      {/* TODO: Current `Modal` component doesn't address `z-index` properly */}
      {isButtonVisible && (
        <BottomButton
          testID="confirm"
          text={`Ver carrito (${quantity})`}
          disabled={!payButtonIsActive}
          icon={isLoading ? <ActivityIndicator /> : undefined}
          onClick={props.handlePayButtonPress}
        />
      )}
    </>
  );
}
