import React from "react";

import {
  RouteComponentProps,
  Redirect,
  useMatch,
  WindowLocation,
  useLocation,
} from "@reach/router";
import { connect } from "react-redux";

import { Copy } from "../constants";
import Breadcrumb from "../features/Catalogue/components/shared/Breadcrumb.web";
import Breadcrumbs from "../features/Catalogue/components/shared/Breadcrumbs.web";
import Header from "../features/Catalogue/components/shared/Header";
import MerchantHeader from "../features/Catalogue/components/shared/MerchantHeader";
import { Merchant } from "../types/Merchant";
import { CartProduct } from "../types/Product";
import CatalogueResponsiveView from "./components/CatalogueResponsiveView";
import { redirect, url } from "./router";

interface PageHeaderProps {
  isFetching: boolean;
  hasInfos: boolean;
  enableBackButton: boolean;
  hasAvatar: boolean;

  username: string;
  merchant: Merchant;
  headerLeft?: React.ReactNode;
  headerTitle?: React.ReactNode;
  headerRight?: React.ReactNode;

  onPressGoBack: () => void;
}

function PageHeader({
  isFetching,
  username,
  merchant,
  headerRight,
  headerTitle,
  headerLeft,
  hasAvatar,
  hasInfos,
  enableBackButton,
  onPressGoBack,
}: PageHeaderProps) {
  // User has reached this screen with incomplete data, most likely he used native browser buttons
  if (isFetching) {
    return null;
  }
  if (!merchant) {
    return <Redirect to={redirect(username)} noThrow />;
  }
  return (
    <Header
      headerRight={headerRight}
      headerTitle={headerTitle}
      headerLeft={
        headerLeft || (
          <MerchantHeader
            merchant={merchant}
            hasAvatar={hasAvatar}
            hasInfos={hasInfos}
            enableBackButton={enableBackButton}
            onPressGoBack={onPressGoBack}
          />
        )
      }
    />
  );
}

const ConnectedPageHeader = connect(({ catalogue }) => {
  return {
    merchant: catalogue.merchant,
    isFetching: catalogue.isFetchingCatalogue,
  };
}, null)(PageHeader);

interface PageProgressProps {
  username: string;
  location?: WindowLocation;
  hasFulfillmentOptions: boolean;
  hasManyPaymentTypes?: boolean;
  isPaymentPlan: boolean;
  isSubscription: boolean;
  cartItems: CartProduct[];
}

export function PageProgress({
  username,
  location,
  hasFulfillmentOptions,
  hasManyPaymentTypes,
  isPaymentPlan,
  isSubscription,
  cartItems,
}: PageProgressProps) {
  // default to empty path is location not present
  const path = location ? location.pathname.replace(`/${username}`, "") : "";

  // Breadcrumbs for Subscription product with Fulfillment
  // subscriptions have fulfillments enabled by default
  if (isSubscription) {
    let current;
    switch (path) {
      case "/customer/email":
      case "/delivery/details":
        current = 2;
        break;
      case "/cart/payment":
        current = 3;
        break;
      case "/checkout/review":
        current = 4;
        break;
      default:
        current = 1;
        break;
    }

    const basePath =
      cartItems.length > 0
        ? `/${username}/p/${cartItems[0]?.short_uuid}/prices`
        : `/${username}`;
    return (
      <Breadcrumbs>
        <Breadcrumb
          active={current >= 1}
          current={current === 1}
          path={basePath}
        >
          {isPaymentPlan
            ? Copy.t("payment_plans")
            : Copy.t("subscriptions.subscription")}
        </Breadcrumb>
        <Breadcrumb
          active={current >= 2}
          current={current === 2}
          path={`/${username}/customer/email`}
        >
          {Copy.t("subscriptions.details")}
        </Breadcrumb>
        <Breadcrumb
          active={current >= 3}
          current={current === 3}
          path={`/${username}/cart/payment`}
        >
          {Copy.t("payment")}
        </Breadcrumb>
        <Breadcrumb
          active={current >= 4}
          current={current === 4}
          path={``}
          last
        >
          {Copy.t("review")}
        </Breadcrumb>
      </Breadcrumbs>
    );
  }
  // Breadcrumbs for product with Fulfillment
  else if (hasFulfillmentOptions) {
    const steps = 4;
    let current;
    switch (path) {
      case "/fulfillments":
      case "/delivery/details":
        current = 2;
        break;
      case "/cart/payment":
      case "/cart/payment/ach":
      case "/cart/payment/selection":
        current = 3;
        break;
      case "/checkout/review":
      case "/cart/review":
        current = 4;
        break;
      default:
        current = 1;
        break;
    }

    return (
      <Breadcrumbs>
        <Breadcrumb
          active={current >= 1}
          current={current === 1}
          path={`/${username}/customer/email`}
        >
          {Copy.t("information")}
        </Breadcrumb>
        <Breadcrumb
          active={current >= 2}
          current={current === 2}
          path={`/${username}/delivery/details`}
        >
          {Copy.t("fulfillment")}
        </Breadcrumb>
        <Breadcrumb
          active={current >= 3}
          current={current === 3}
          path={`/${username}/cart/payment`}
        >
          {Copy.t("payment")}
        </Breadcrumb>
        <Breadcrumb
          active={current >= 4}
          current={current === 4}
          path={``}
          last
        >
          {Copy.t("review")}
        </Breadcrumb>
      </Breadcrumbs>
    );
  }
  // Breadcrumbs for product with no Fulfillment
  const steps = 3;
  let current;

  switch (path) {
    case "/cart/payment":
    case "/cart/payment/ach":
    case "/cart/payment/selection":
      current = 2;
      break;
    case "/checkout/review":
    case "/cart/review":
      current = 3;
      break;
    default:
      current = 1;
      break;
  }

  return (
    <Breadcrumbs>
      <Breadcrumb
        active={current >= 1}
        current={current === 1}
        path={`/${username}/customer/email`}
      >
        {Copy.t("information")}
      </Breadcrumb>
      <Breadcrumb
        active={current >= 2}
        current={current === 2}
        path={`/${username}/cart/payment`}
      >
        {Copy.t("payment")}
      </Breadcrumb>
      <Breadcrumb active={current >= 3} current={current === 3} path={``} last>
        {Copy.t("review")}
      </Breadcrumb>
    </Breadcrumbs>
  );
}

export const ConnectedPageProgress = connect(({ catalogue, cart }) => {
  return {
    hasFulfillmentOptions: catalogue.hasFulfillmentOptions,
    hasManyPaymentTypes: catalogue.hasManyPaymentTypes,
    isSubscription: cart.isSubscription,
    cartItems: cart.productList,
    isPaymentPlan: cart.isPaymentPlan,
  };
}, null)(PageProgress);

interface Props extends RouteComponentProps {
  children: any;

  username?: string;
}

export const CatalogueLayout = (props: Props) => {
  const { username, children } = props;

  let root = "/:username";
  // Sometimes we set the username as a subdomain. This is used in 'cool' links like
  // miranda.cuan.to, etc.
  if (url.subdomain) {
    root = "/";
  }

  const location = useLocation();
  const isCatalogue = useMatch(`${root}`);
  const isCategory = useMatch(`${root}/category/:category_uuid`);

  const isHome = isCatalogue || isCategory ? true : false;

  return (
    <CatalogueResponsiveView scrollToTop={isHome}>
      {/* https://github.com/reach/router/issues/163 */}
      {React.cloneElement(children, {
        children: React.Children.map(children.props.children, (child) => {
          return React.cloneElement(child, {
            // Passed as slot prop since each Screen might want to customize its behaviour
            header: <ConnectedPageHeader username={username} />,
            progress: (
              <ConnectedPageProgress username={username} location={location} />
            ),
          });
        }),
      })}
    </CatalogueResponsiveView>
  );
};

export default CatalogueLayout;
