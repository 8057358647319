import React, { useEffect, useState } from "react";

import { LoadingView } from "cuanto/components";
import { ConnectedToApp } from "cuanto/web-app/EcommerceApp";

import {
  PreviewBanner,
  PreviewLinks,
  PreviewBio,
  PreviewColors,
  PreviewCatalogue,
} from ".";
import useMessageListener from "./useMessageListener";


// TODO: type out props
function PreviewScreen(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const componentName = urlParams.get("name");

  const [componentProps, setComponentProps] = useState(
    Object.fromEntries(urlParams.entries())
  );

  useMessageListener(setComponentProps);

  useEffect(() => {
    props.fetchCatalogue(props.username);
  }, []);

  // TODO: figure out which props we care about instead of this so we canload properly
  if (!props.merchant) return <LoadingView />;

  function getComponent() {
    switch (componentName) {
      case "catalogue":
        return <PreviewCatalogue {...props} {...componentProps} />;
      case "banner":
        return <PreviewBanner {...props} {...componentProps} />;
      case "links":
        return <PreviewLinks {...props} {...componentProps} />;
      case "bio":
        return <PreviewBio {...props} {...componentProps} />;
      case "colors":
        return <PreviewColors {...props} {...componentProps} />;
      default:
        return <div>Unknown component</div>;
    }
  }

  return <div id="preview-container">{getComponent()}</div>;
}

export default ConnectedToApp(PreviewScreen);
