import { useEffect, useState, useReducer } from "react";

import toPairs from "lodash/toPairs";
import filter from "lodash/filter";

function reducer(state, { type, payload }) {
  switch (type) {
    case "filter":
      return {
        variant_options: { ...state.variant_options, ...payload },
      };

    default:
      return state;
  }
}

export function useProductVariants({ productGroup, productVariants = [] }) {
  const [products, setProducts] = useState([]);
  const [variants, setVariants] = useState(null);
  const [filters, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    let variantsMap = new Map();
    productVariants.map((product) => {
      // variant_options: { color: "Red", size: "M" } }
      const pairs = toPairs(product.variant_options);
      // [["color", "Red"], ["size", "M"]]
      pairs.map(([k, v]) => {
        // Working under the assumption that keys from data in backend are consistent.
        variantsMap.set(k, (variantsMap.get(k) || new Set()).add(v));
      });
      // Map(2) {"size" => Set(3) {"M", "XL", "L"}, "color" => Set(2) {"Blue", "Red"}}
    });

    setProducts(productVariants);
    setVariants(variantsMap);
  }, [productGroup.uuid]);

  const filtered = filter(products, filters);

  return { products, variants, filters, filtered, dispatch };
}
