import React from "react";

import { mdiCartPlus } from "@mdi/js";
import Icon from "@mdi/react";

import useTimedToast from "cuanto/hooks/useTimedToast";
import Button from "cuanto/web-app/components/Button.web";

type Props =
  | { toast: string; onPress?: never; quantity?: never }
  | { toast: string; onPress: () => void; quantity: number };

// displays an autoclearing toast with an optional button to pay now
export default function CataloguePayButtonToast({ toast, ...btnProps }: Props) {
  const isShowing = useTimedToast(toast);

  if (!isShowing) return null;

  return (
    <div
      className="fixed top-0 w-ecommerce z-50 "
      data-testid="add-to-cart-toast"
    >
      <div className="flex items-center justify-center bg-gray-50 h-14 shadow-xl">
        <Icon path={mdiCartPlus} className="text-primary-500 mr-4 h-5 w-5" />
        <span className="text-gray-800 leading-6">{toast}</span>
      </div>

      <PayButton {...btnProps} />
    </div>
  );
}

function PayButton({ quantity, onPress }: Pick<Props, "quantity" | "onPress">) {
  if (!quantity || !onPress) return null;

  return (
    <div className="pt-2 px-4">
      <Button text={`Pagar (${quantity})`} onClick={onPress} small />
    </div>
  );
}
