import { useState, useEffect } from "react";

const TIMEOUT = 5 * 1000;

/**
 * Tracks visibility of a toast message that dismisses itself after a timeout
 * Value is reset every time the message changes
 */
export default function useTimedToast(message: string) {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    if (!message) return;

    setIsShowing(true);
    const timerId = setTimeout(() => setIsShowing(false), TIMEOUT);

    return () => {
      setIsShowing(false);
      clearTimeout(timerId);
    };
  }, [message]);

  return isShowing;
}
