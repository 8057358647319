import React, { useState } from "react";

import isEmpty from "lodash/isEmpty";

import { useProductVariants } from "../../../hooks/useProductVariants";
import { Product } from "../../../types/Product";
import ProductGroupModalContent from "./ProductGroupModalContent.web";

interface Props {
  visible: boolean;
  productGroup: Product;
  productVariants: Product[];
  storeClosed: boolean;
  handleAddToCart: Function;
  handleRequestClose: () => void;
}

const ProductGroupModal = (props: Props) => {
  if (!props.visible || isEmpty(props.productGroup)) {
    return null;
  }

  const [showVariantError, setShowVariantError] = useState(false);
  const { productGroup, productVariants, visible, storeClosed } = props;
  const { handleAddToCart, handleRequestClose } = props;

  const {
    products,
    variants,
    filters,
    filtered,
    dispatch,
  } = useProductVariants({ productGroup, productVariants });

  return (
    <ProductGroupModalContent
      visible={visible}
      products={products}
      filtered={filtered}
      productGroup={productGroup}
      variants={variants}
      storeClosed={storeClosed}
      filters={filters}
      showVariantError={showVariantError}
      setShowVariantError={setShowVariantError}
      dispatch={dispatch}
      handleAddToCart={handleAddToCart}
      handleRequestClose={handleRequestClose}
      title={"Elige las variables de tu producto"}
    />
  );
};

export default ProductGroupModal;
