import React, { useState } from "react";

import filter from "lodash/filter";
import get from "lodash/get";

import { hasInventory } from "../../../services/Product";
import Select from "../Select.web";

export default function ProductVariantPicker({
  value,
  variants,
  products,
  showVariantError = false,
  onChange,
}) {
  const [lastFilter, setLastFilter] = useState("");

  if (!variants || variants.size < 1) {
    return null;
  }

  const outOfStockLabel = "(AGOTADO)";
  const variantsKeys = Array.from(variants.keys());
  const filtersKeys = Object.keys(get(value, "variant_options", []));

  // We render `option`s without `value`s to keep them showing the `outOfStockLabel`
  // but the filters should be applied from their original values
  const originalFilters = {
    variant_options: {},
  };
  filtersKeys.forEach((key) => {
    originalFilters.variant_options[key] = value.variant_options[key].replace(
      ` ${outOfStockLabel}`,
      ""
    );
  });

  const filteredVariants = filter(products, originalFilters);
  return (
    <div className="flex flex-col">
      {variantsKeys.map((key: string) => {
        const attributes = variants.get(key);
        const filteredVariant = filteredVariants[0];

        const isLastSelected = lastFilter === key;
        const isVariantOutOfStock =
          filteredVariants.length === 1 && !hasInventory(filteredVariant);

        return (
          <Select
            testId={`product-variant-${key}`}
            key={key}
            defaultValue={key}
            error={
              isLastSelected && isVariantOutOfStock
                ? "Este modelo se encuentra agotado. Por favor, selecciona otro modelo."
                : ""
            }
            onChange={({ target: { value } }) => {
              setLastFilter(key);
              onChange(key, value);
            }}
          >
            <option key={key} disabled hidden>
              {key}
            </option>
            {Array.from(attributes).map((attribute: string) => {
              const attributesFilter = {
                variant_options: {
                  ...value.variant_options,
                  ...{ [key]: attribute },
                },
              };
              const productsWithAttributes = filter(products, attributesFilter);
              const productWithAttributes = productsWithAttributes[0];

              const isOutOfStock =
                productsWithAttributes.length === 1 &&
                !hasInventory(productWithAttributes);

              return (
                <option
                  key={attribute}
                  data-testid={`product-variant-option-${attribute}`}
                  className="text-gray-900"
                >
                  {attribute} {isOutOfStock && outOfStockLabel}
                </option>
              );
            })}
          </Select>
        );
      })}
      {showVariantError && filteredVariants.length !== 1 && (
        <div className="mb-2 text-red-600">
          Por favor selecciona una opción para agregar al carrito.
        </div>
      )}
    </div>
  );
}
