import React from "react";

import CartIcon from "../../../assets/svgs/Cart";
import { Copy } from "../../../constants";
import { Product } from "../../../types/Product";
import Button from "../Button.web";

const CLOSED_LABEL = "Tienda Cerrada";
const OUT_OF_STOCK_LABEL = "Agotado";

export interface Props {
  product: Product;
  storeClosed?: boolean;
  isOutOfStock?: boolean;
  onClick: () => void;
}

export default function CatalogueListItemButton(props: Props) {
  const { isOutOfStock = false, storeClosed = false } = props;
  const disabled = isOutOfStock || storeClosed;

  const buttonText = (): string => {
    if (storeClosed) {
      return CLOSED_LABEL;
    } else if (isOutOfStock) {
      return OUT_OF_STOCK_LABEL;
    } else {
      return Copy.t("catalogue.addToCart");
    }
  };

  return (
    <Button
      text={buttonText()}
      testID={
        disabled
          ? "cataloguelist-item-outofstock"
          : "cataloguelist-item-addtocart"
      }
      small
      icon={<CartIcon />}
      buttonType="secondary"
      onClick={props.onClick}
      disabled={disabled}
    />
  );
}
