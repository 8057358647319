import { Category } from "./Category";
import { FulfillmentType } from "./FulfillmentOption";
import { Price as PaymentPrice } from "./Payment";

export interface Product extends PaymentPrice {
  name: string;
  description: string;
  short_description: string;
  price_not_discounted: number | null;

  id: number;
  image_url: string;
  images: string[];
  uuid: string;
  short_uuid: string;
  published: boolean;
  url: string;
  user_id: number;
  inventory: Inventory;
  updated_at: Date;
  sale_email: string;
  categories: Category[];
  google_product_category?: number;

  variant_options: ProductVariantOptions;

  fulfillment_type: FulfillmentType;
  ecommerce_view: EcommerceView;
  benefits: string[];

  draft: boolean;
  default_sort_index: number | null;
}

export enum EcommerceView {
  DEFAULT = "default",
  SUBSCRIPTIONS = "subscriptions",
}

export enum PaymentPlanType {
  ONE_OFF = "one_off",
  RECURRING = "recurring",
  INSTALLMENT = "installment",
}

export type Price = {
  uuid: string;
  amount: number;
  recurring_interval: RecurringInterval;
  recurring_interval_count: number;
  name: string;
  payment_plan_type: PaymentPlanType;
  installments_count?: number;
  installments: Date[];
  inventory?: Inventory;
  sold_out?: boolean;
};

export type RecurringInterval = "day" | "week" | "month" | "year";

export interface CartProduct extends Product {
  name: string;
  description: string;
  price_uuid?: string;

  quantity: number;
  icon?: JSX.Element;
  hasIcon: boolean;
}

export interface Inventory {
  enabled: boolean;
  quantity: number;
}

// Summary of a product's variants and options
// An object with type ProductVariantOptions represents a Combination
// by making reference to the variant name and the option selected for that variant
// Example: { Color: Blue, Size: Small }
export interface ProductVariantOptions {
  // keys are variant names, values are option names
  [key: string]: string;
}

export interface Variant {
  name: string;
  uuid: string;
  variant_options: VariantOption[];
}

export interface VariantOption {
  uuid: string;
  name: string;
}

export interface VariantOptionExtendend extends VariantOption {
  variant: {
    name: string;
    uuid: string;
  };
}

export interface Combination {
  inventory: Number;
  tracking_status: boolean;
  uuid: string;
  variant_options: VariantOptionExtendend[];
}
