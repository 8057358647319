import React from "react";

import get from "lodash/get";
import { connect } from "react-redux";

import * as cartActions from "../features/Cart/actions";
// TODO: extract shouldn't be here as we don't log unless on screens
import { captureException } from "../services/Sentry.web";
import * as actions from "./actions";
import { checkInventoryAndUpdateCart, getProductUuids } from "./inventory";
// TODO: extract where we setup the app
import { navigate, redirect } from "./router";
import {
  CatalogueCart,
  CatalogueReviews,
  FulfillmentOptionsList,
  ProductLink,
} from "./Screens";
import * as selectors from "./selectors";
import { store } from "./State";

// TODO: App 'slice' for Catalogue feature should be in the feature's directory.
export default class Catalogue {
  store: any;
  navigate: Function;
  redirect: Function;
  props: Object = {};

  constructor(store, navigate, redirect) {
    this.store = store;
    this.navigate = navigate;
    this.redirect = redirect;
  }

  // Sets props in the app object. Used in ConnectToApp.
  connect = (props) => {
    for (const key in props) {
      this.props[key] = props[key];
    }
  };

  init() {
    this.prefetchNextRoutes("CatelogueScreen");
    this.props.fetchCatalogue(this.props.username);
    this.props.fetchSocialStats(this.props.username);

    const isCategory = this.props.path.includes("category");

    // if list is filtered (selectedCategoryUuid is not "all") send it to correct link
    if (!isCategory && this.props.selectedCategoryUuid !== "all") {
      this.selectCategory(this.props.selectedCategoryUuid);
    }
    // if list is in category link, but not filtered, filter it
    else if (isCategory && this.props.selectedCategoryUuid === "all") {
      this.props.selectCategoryUuid(this.props.category_uuid);
    }
  }

  // get username() {
  //   const routeParams = pick(
  //     [{ path: "/:username/*" }],
  //     window.location.pathname
  //   );
  //   // Getting the username from the URL is more certain as store loads async.
  //   // return this.store.getState().catalogue.merchant.username;
  //   return get(routeParams, "params.username");
  // }

  // Prefetch screens for faster transitions.
  prefetchNextRoutes(currentScreen) {
    switch (currentScreen) {
      case "CatelogueScreen":
        ProductLink.preload();
        CatalogueCart.preload();
        FulfillmentOptionsList.preload();
        CatalogueReviews.preload();
        break;
      default:
        throw new Error(
          `App.prefetchNextRoutes doesn't have entry for ${currentScreen}`
        );
    }
  }

  navigateToProductList = () => {
    this.navigate(this.props.username);
  };

  navigateToFulfillmentInfo = () => {
    this.navigate(`${this.props.username}/fulfillments/info`);
  };

  navigateToReviews = () => {
    this.navigate(this.props.username, `/customers/reviews`);
  };

  selectCategory(uuid) {
    this.props.selectCategoryUuid(uuid);
    if (uuid === "all") {
      this.navigateToProductList();
    } else {
      this.navigate(this.props.username, `/category/${uuid}`);
    }
  }

  selectProduct(product) {
    this.navigate(this.props.username, `/p/${product.short_uuid}`, {
      state: { product },
    });
  }

  addToCart(product) {
    this.props.clearSelectedPrice();
    this.props.addToCart(product);
  }

  showCartIfInventoryIsValid = async () => {
    const { username, merchant, productUuids, cartList } = this.props;
    const {
      updateProductQuantity,
      removeFromCart,
      catalogueLoadingStart,
      catalogueLoading,
      catalogueLoadingError,
    } = this.props;

    catalogueLoadingStart("inventory");
    try {
      const errors = await checkInventoryAndUpdateCart({
        uuids: productUuids,
        cartList,
        updateProductQuantity,
        removeFromCart,
      });

      if (errors.length) {
        window.alert("¡Ups! \n" + errors.join("\n"));
      } else {
        this.navigate(username, `/cart`, { state: { merchant } });
      }
      catalogueLoading("inventory");
    } catch (error) {
      catalogueLoadingError("inventory");
      captureException(error);
    }
  };

  // If no product have been created
  // Or disabled by flags (empty response, no `merchant` and no `products`)
  // show the current Paylink page (open-amount charge screen)
  get hasStore() {
    const { merchant, products } = this.props;
    return (
      get(merchant, "has_ecommerce_store", false) &&
      get(products, "length", 0) > 0
    );
  }
}

// TODO: extract setup, we shouldn't be doing it here.
// Only need one app object
const app = new Catalogue(store, navigate, redirect);
// Attaching the app to `window` allows driving it from the console.
window.app = app;
window.a = app; // for convenience

const mapStateToProps = ({ catalogue, cart, discounts }, ownProps) => {
  return {
    merchant: catalogue.merchant,
    storeConfig: catalogue.storeConfig,
    featureFlags: catalogue.featureFlags,
    products: selectors.selectProducts(catalogue),
    categories: selectors.selectCategories(catalogue),
    filteredProducts: selectors.selectCategoryProducts(catalogue),
    productGroups: catalogue.productGroupsById,
    isCatalogueFetched: catalogue.isCatalogueFetched,
    hasFetchingErrored: catalogue.hasFetchingCatalogueErrored,

    transactionCountPastThreeMonths: catalogue.transactionCountPastThreeMonths,
    reviewsCount: catalogue.reviewsCount,

    isLoadingInventory: catalogue.isCatalogueLoading["inventory"],

    fulfillmentOptions: catalogue.fulfillmentOptions,
    fulfillmentOptionsSetting: catalogue.fulfillmentOptionsSetting,
    storeClosed: catalogue.storeClosed,

    total: cart.total,
    quantity: cart.quantity,
    productUuids: getProductUuids(cart.productList),
    cartList: cart.cartList,

    selectedCartDiscount: discounts.selectedCartDiscount,
    selectedCategoryUuid: catalogue.selectedCategoryUuid,
  };
};

const mapDispatchToProps = {
  // Init the Catalogue
  fetchCatalogue: actions.async.fetchCatalogue,
  fetchSocialStats: actions.async.fetchSocialStats,
  // Cart actions
  addToCart: cartActions.addToCart,
  removeFromCart: cartActions.removeFromCart,
  updateProductQuantity: cartActions.updateProductQuantity,
  clearSelectedPrice: cartActions.clearSelectedPrice,
  setTracker: cartActions.setTracker,

  catalogueLoadingStart: actions.catalogueLoadingStart,
  catalogueLoading: actions.catalogueLoading,
  catalogueLoadingError: actions.catalogueLoadingError,
  selectCategoryUuid: actions.selectCategoryUuid,
};

function ConnectedToApp(Component: React.Component) {
  function WrappedComponent(props) {
    app.connect(props);
    return <Component {...props} />;
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
}

// Wire up the app to Redux. Wrapped in a component to comply with react-redux `connect`.
function ConnectAppForConsole() {
  function WrappedComponent(props) {
    app.connect(props);
    return null;
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
}

export { app, ConnectedToApp, ConnectAppForConsole };
