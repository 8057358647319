import React from "react";

import { Modal as ModalComponent, Platform } from "react-native";
import Svg, { Path } from "react-native-svg";
import styled, { css } from "styled-components/native";
import { getColor } from "tailwind-rn";

import { Colors, Layout } from "../constants";
import { Divider as StyledDivider } from "./Divider";

const isWEB = Platform.OS === "web";
const MAX_WIDTH = 600;

export interface Props {
  visible: boolean;
  children: React.ReactNode[];
  onRequestClose: () => void;
}

const Modal = (props: Props) => {
  const { children, visible, onRequestClose } = props;

  const footer = children.filter((child) => child.type === Footer);
  return (
    <ModalComponent
      transparent
      animationType={window && window.Cypress ? "none" : "slide"}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <Overlay
        testID="modal-overlay"
        onPress={(event) => {
          if (event.target.dataset?.testid === "modal-overlay") {
            onRequestClose();
          }
        }}
      >
        <Container>
          <BodyWrapper hasFooter={footer}>
            <HeadWrapper>
              <CloseOval onPress={onRequestClose}>
                <Close />
              </CloseOval>

              {children.filter((child) => child.type === Header)}
            </HeadWrapper>

            <Wrapper>{children.filter((child) => child.type === Body)}</Wrapper>
          </BodyWrapper>
        </Container>
        <FooterWrapper>{footer}</FooterWrapper>
      </Overlay>
    </ModalComponent>
  );
};

export default Modal;

const Overlay = ({ children, ...rest }) => {
  return isWEB ? (
    <OverlayWeb {...rest}>{children}</OverlayWeb>
  ) : (
    <OverlayMobile {...rest}>{children}</OverlayMobile>
  );
};
const Header = ({ children }) => {
  return children;
};
const Body = ({ children }) => {
  return children;
};
const Footer = ({ children }) => {
  return children;
};

export { Header, Body, Footer };

export const OverlayStyles = css`
  flex: 1;
  background: rgba(0, 0, 0, 0.4);
  z-index: 300;
  align-items: center;
`;
const OverlayWeb = styled.Pressable`
  ${OverlayStyles};

  cursor: default;
`;
const OverlayMobile = styled.View`
  ${OverlayStyles};
`;

const HeadWrapper = styled.View`
  margin-top: ${Layout.vSize(26)}px;
  margin-horizontal: ${Layout.hSize(16)}px;
`;

const Divider = styled(StyledDivider)`
  margin-bottom: 0px;
`;

const Container = styled.View`
  flex: 1;
  align-items: center;

  ${isWEB &&
  css`
    cursor: default;
  `}
`;

const BodyWrapper = styled.ScrollView`
  min-height: ${Layout.vSize(410)}px;
  width: ${isWEB ? "100vw" : "100%"};
  max-width: ${isWEB ? `${MAX_WIDTH}px` : "100%"};
  position: absolute;
  bottom: 0;
  background: ${Colors.white};

  border-top-right-radius: ${Layout.mSize(10)}px;
  border-top-left-radius: ${Layout.mSize(10)}px;
  overflow: hidden;

  ${(props) =>
    props.hasFooter &&
    css`
      padding-bottom: ${Layout.buttonHeight}px;
    `}
`;

const Wrapper = styled.View`
  padding: ${Layout.mSize(16)}px;
`;

const FooterWrapper = styled.View`
  position: absolute;
  bottom: 0;
  width: ${isWEB ? "100vw" : "100%"};
  max-width: ${isWEB ? `${MAX_WIDTH}px` : "100%"};
`;

const CloseOval = styled.TouchableOpacity`
  position: absolute;
  width: ${Layout.mSize(62)}px;
  height: ${Layout.mSize(44)}px;
  top: ${-Layout.vSize(8)}px;
  right: ${-Layout.hSize(16)}px;
  z-index: 1;
`;

function Close() {
  return (
    <Svg width={62} height={44} viewBox="0 0 62 44" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 37c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z"
        stroke={getColor("gray-100")}
        fill={getColor("gray-100")}
      />
      <Path
        d="M25.906 26c-.383.375-.39 1.063.008 1.46.398.4 1.086.384 1.461.009L31 23.844l3.625 3.617c.39.39 1.063.398 1.46-.008a1.05 1.05 0 00.009-1.46l-3.617-3.618 3.617-3.625a1.05 1.05 0 00-.008-1.46 1.042 1.042 0 00-1.461-.009L31 20.898l-3.625-3.625c-.375-.375-1.063-.39-1.46.008-.4.399-.392 1.086-.009 1.469l3.625 3.625L25.906 26z"
        fill={getColor("gray-400")}
        stroke={getColor("gray-100")}
      />
    </Svg>
  );
}
