import React from "react";

import Icon from "@mdi/react";
import { mdiCart, mdiMoped, mdiStar } from "@mdi/js";

export function TransactionCountStat({ txnCount }: { txnCount: number }) {
  if (txnCount === 0) return null;

  function getText() {
    const word = txnCount > 1 ? "ventas" : "venta";
    if (txnCount >= 1000) {
      return `mil+ ${word}`;
    } else if (txnCount >= 100) {
      return `100+ ${word}`;
    } else if (txnCount >= 10) {
      return `10+ ${word}`;
    } else {
      return `${txnCount} ${word}`;
    }
  }

  const text = getText();

  return <Stat icon={mdiCart} text={text} />;
}

export function AverageReviewStat({ average }: { average: number | null }) {
  if (average === null) return null;

  // cast to 1 digit and then back to a number, so 5.0 becomes 5 but 4.9 stays 4.9
  const casted = Number(average.toFixed(1));

  return <Stat icon={mdiStar} text={casted} />;
}

export function DeliveryStat({ enabled }: { enabled: boolean }) {
  if (!enabled) return null;

  return <Stat icon={mdiMoped} text="ofrece entregas" />;
}

function Stat({ icon, text }: { icon: string; text: string | number }) {
  return (
    <div className="text-gray-400 flex items-center">
      <Icon path={icon} className="h-4 w-4 mr-1" />
      <span className="text-sm">{text}</span>
    </div>
  );
}
