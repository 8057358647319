import React from "react";

import { View, Text } from "react-native";

import CuantoLogo from "../../../../assets/svgs/CuantoLogo";
import { tailwind, TailwindStyle } from "../../../../tailwind";

interface Props {
  viewStyle?: TailwindStyle;
  textStyle?: TailwindStyle;
  logoStyle?: TailwindStyle;
}
const CuantoLoveFooter = ({ viewStyle, textStyle, logoStyle }: Props) => {
  return (
    <View
      style={[tailwind("flex-row items-center justify-center pb-8"), viewStyle]}
    >
      <Text style={[tailwind("pr-1 cuanto-gray"), textStyle]}>
        Creado con ❤️ usando
      </Text>
      <CuantoLogo style={logoStyle} />
    </View>
  );
};

export default CuantoLoveFooter;
