import { createSlice } from "@reduxjs/toolkit";

import { ConsumerSession } from "../../../types/Consumer";
import { DeliveryAddress } from "../../../types/Delivery";
import { ConsumerPaymentMethod } from "../../../types/PaymentMethod";
import * as actions from "./actions";

export interface State {
  email: string;
  name: string;
  session: {} | ConsumerSession;
  deliveryAddress: DeliveryAddress | null;
  paymentMethod: ConsumerPaymentMethod;
  paymentMethodCode: string;
  rememberDetails: boolean;
  isGuestSession: boolean;

  isLoading: {
    addresses: boolean;
    paymentMethods: boolean;
  };
  hasErrored: {
    addresses: boolean;
    paymentMethods: boolean;
  };

  addresses: DeliveryAddress[];
  paymentMethods: ConsumerPaymentMethod[];
}

export const initialState: State = {
  email: "",
  name: "",
  session: {},
  deliveryAddress: null,
  paymentMethod: null,
  paymentMethodCode: "",
  rememberDetails: true,
  isGuestSession: false,

  addresses: [],
  paymentMethods: [],

  isLoading: {
    addresses: false,
    paymentMethods: false,
  },
  hasErrored: {
    addresses: false,
    paymentMethods: false,
  },
};

const consumer = createSlice({
  name: "consumer",
  initialState,
  reducers: {
    // Slice reducers can't use imported actions because they create new actions
    updateRememberDetails(state, action) {
      state.rememberDetails = action.payload;
    },
    resetConsumerPaymentMethods(state, action) {
      state.paymentMethods = initialState.paymentMethods;
    },
    resetConsumerAddresses(state, action) {
      state.addresses = initialState.addresses;
    },
    resetDeliveryAddress(state) {
      state.deliveryAddress = initialState.deliveryAddress;
    },
    updateGuestSession(state, action) {
      const isGuest = action.payload;
      state.isGuestSession = isGuest;
      state.rememberDetails = !isGuest;
    },
  },
  extraReducers: {
    [actions.setConsumerEmail.type]: (state, { payload }) => {
      state.email = payload;
    },
    [actions.setConsumerName.type]: (state, { payload }) => {
      state.name = payload;
    },
    [actions.async.createConsumerSession.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.session = payload;
    },

    [actions.async.fetchConsumerAddresses.pending.type]: (state) => {
      state.isLoading.addresses = true;
      state.hasErrored.addresses = false;
    },
    [actions.async.fetchConsumerAddresses.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.isLoading.addresses = false;
      state.hasErrored.addresses = false;
      state.addresses = payload;
    },
    [actions.async.fetchConsumerAddresses.rejected.type]: (state) => {
      state.isLoading.addresses = false;
      state.hasErrored.addresses = true;
    },

    [actions.async.fetchConsumerPaymentMethods.pending.type]: (state) => {
      state.isLoading.paymentMethods = true;
      state.hasErrored.paymentMethods = false;
    },
    [actions.async.fetchConsumerPaymentMethods.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.isLoading.paymentMethods = false;
      state.hasErrored.paymentMethods = false;
      state.paymentMethods = payload;
    },
    [actions.async.fetchConsumerPaymentMethods.rejected.type]: (state) => {
      state.isLoading.paymentMethods = false;
      state.hasErrored.paymentMethods = true;
    },

    [actions.updateDeliveryAddress.type]: (state, action) => {
      state.deliveryAddress = action.payload;
    },

    [actions.updatePaymentMethod.type]: (state, { payload }) => {
      state.paymentMethod = payload;
    },
    [actions.async.updateConsumerPaymentMethod.fulfilled.type]: (
      state,
      { payload }
    ) => {
      const index = state.paymentMethods.findIndex(
        (pm) => pm.id === payload.id
      );

      const clonedPaymentMethods = [...state.paymentMethods];
      clonedPaymentMethods.splice(index, 1, payload);

      state.paymentMethods = clonedPaymentMethods;
    },
    [actions.async.updateConsumerPaymentMethod.rejected.type]: (
      state,
      { payload }
    ) => {
      // TODO: should we log to sentry or anywhere else on failed requests?
    },
    [actions.async.updateConsumerPaymentMethods.type]: (state, { payload }) => {
      state.paymentMethods = payload;
    },
    [actions.updatePaymentMethodCode.type]: (state, { payload }) => {
      state.paymentMethodCode = payload;
    },
    [actions.resetPaymentMethod.type]: (state, { payload }) => {
      state.paymentMethod = initialState.paymentMethod;
      state.paymentMethodCode = initialState.paymentMethodCode;
    },
  },
});

export default consumer;

export const {
  updateRememberDetails,
  resetConsumerPaymentMethods,
  resetConsumerAddresses,
  resetDeliveryAddress,
  updateGuestSession,
} = consumer.actions;
