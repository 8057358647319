import React from "react";


import { Merchant } from "cuanto/types/Merchant";
import Button from "cuanto/web-app/components/Button.web";
import CatalogueHeader from "cuanto/web-app/components/CatalogueHeader";
import CategoriesPills from "cuanto/web-app/components/CategoriesPills.web";
import Catalogue from "cuanto/web-app/components/ProductCatalogue/Catalogue.web";
import CatalogueBanner, {
  BannerProps,
} from "cuanto/web-app/components/ProductCatalogue/CatalogueBanner";
import CatalogueBio from "cuanto/web-app/components/StorePersonalization/CatalogueBio";
import StoreLinks from "cuanto/web-app/components/StorePersonalization/StoreLinks";
import {
  Link,
  StoreConfig,
} from "cuanto/web-app/components/StorePersonalization/types";

// props for all preview components include common redux props
// + whatever they need to inject for overwriting preview
type WithReduxProps<T = {}> = T & {
  merchant: Merchant;
  storeConfig: StoreConfig;

};

export function PreviewLinks(props: WithReduxProps<{ links?: Link[] }>) {
  // if there is no injected link prop, default to storeConfig
  const links =
    props.links === undefined ? props.storeConfig.links : props.links;

  return (
    <div className="m-4 select-none">
      <div className="pointer-events-none">
        <PreviewHeader />
      </div>
      <div className="mt-3">
        <StoreLinks links={links} disableClicks />
      </div>
    </div>
  );
}

export function PreviewBio(props: WithReduxProps<{ bio?: string }>) {
  // if there is no injected bio prop, default to storeConfig
  // if there is a bio prop, override storeConfig even when bio prop is ""
  const bio = props.bio === undefined ? props.storeConfig.bio : props.bio;

  return (
    <div className="m-4 select-none pointer-events-none">
      <PreviewHeader />
      <CatalogueBio bio={bio} />
    </div>
  );
}

export function PreviewColors(props: WithReduxProps) {
  const categories = [1, 2, 3].map((n) => ({
    uuid: "",
    name: `Categoría ${n}`,
  }));

  const links = props.storeConfig.links.map((l) => ({ ...l, enabled: true }));

  return (
    <div className="m-4">
      <StoreLinks links={links} disableClicks />
      <div className="my-4">
        <CategoriesPills
          categories={categories}
          onCategoryClick={() => {}}
          resetSelectedCategoryPillVisbile
        />
      </div>
      <div className="flex gap-x-4 items-center">
        <Button text="Boton 1" small />
        <Button text="Boton 2" buttonType="secondary" small />
      </div>
    </div>
  );
}

type CatalogueProps = {
  bio?: string;
  links?: Link[];
  bannerUrl?: string;
};

export function PreviewCatalogue(props: WithReduxProps<CatalogueProps>) {
  const bannerUrl = props.bannerUrl || props.storeConfig.banner_image_url;
  const links =
    props.links === undefined ? props.storeConfig.links : props.links;
  const bio = props.bio === undefined ? props.storeConfig.bio : props.bio;

  const storeConfig: StoreConfig = {
    ...props.storeConfig,
    bio,
    banner_image_url: bannerUrl,
    links,
  };

  return (
    <Catalogue
      {...props}
      storeConfig={storeConfig}
      hasStoreConfigEnabled
      numOfCatalogueColumns={2}
    />
  );
}

// TODO: figure out how we wanna type this
export function PreviewBanner(props: WithReduxProps<BannerProps>) {
  const bannerUrl = props.bannerUrl || props.storeConfig.banner_image_url;

  return (
    <div>
      <CatalogueBanner bannerUrl={bannerUrl} showPlaceholderIcon />
      <div className="m-4">
        <PreviewHeader />
      </div>
    </div>
  );
}

// simple wrapper to prevent clicks on header / navigation
function PreviewHeader() {
  return (
    <div className="pointer-events-none">
      <CatalogueHeader />
    </div>
  );
}
