import React from "react";

import ChevronDown from "../../assets/svgs/ChevronDown";

export default function Select({ children, testId, error, ...rest }) {
  return (
    <>
      <div className="inline-block relative mb-2">
        <select
          {...rest}
          data-testid={`${testId}`}
          className={`block appearance-none rounded-md w-full border border-solid border-white-500 px-4 py-3 pr-8 leading-tight focus:outline-none text-base font-sans ${
            error
              ? "bg-red-50 border-red-600 text-red-600"
              : "bg-white-100 text-gray-900"
          }`}
        >
          {children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center px-2 text-gray-400">
          <ChevronDown />
        </div>
      </div>
      {error && (
        <div className="mb-2 text-red-600" data-testid={`${testId}-error`}>
          {error}
        </div>
      )}
    </>
  );
}
