import React, { Suspense } from "react";

import { Router } from "@reach/router";
import lazy from "react-lazy-with-preload";
import { connect } from "react-redux";

import { setUtm } from "../features/Cart/actions";
import { retry } from "../services/network";
import CatalogueLayout from "./CatalogueLayout";
import { colorPaletteAsCssFromHex } from "./colors";
import PreviewScreen from "./features/Preview/PreviewScreen";
import { url } from "./router";
import RouterListener from "./RouterListener";
import {
  PaymentRequest,
  ProductLink,
  ProductQuestion,
  Consumer,
  CatalogueCart,
  CatalogueQuantity,
  CatalogueCartPayment,
  CatalogueMoreInfo,
  CheckoutReview,
  PaymentRequestReview,
  PaymentResult,
  CatalogueReviews,
  CatalogueReviewForm,
  FulfillmentOptionsList,
  ConsumerDeliveryInfoForm,
  ConsumerDeliverySelection,
  CatalogueDiscountForm,
  Paylink,
  SubscriptionPrice,
  PaylinkPayment,
  NotFound,
  SubscriptionCancellation,
  MerchantAdminDashboard,
  MerchantAdminProductForm,
  ConsumerValidation,
} from "./Screens";

const Catalogue = lazy(() =>
  retry(() => import("./screens/CatalogueScreen.web"))
);

import "./Catalogue.css";
import "./tailwind.css";

function Root(reduxProps) {
  const props: any = {};

  let root = "/:username";
  // Sometimes we set the username as a subdomain. This is used in 'cool' links like
  // miranda.cuan.to, etc.
  if (url.subdomain) {
    props.username = url.subdomain;
    root = "/";
  }

  // Capture utm query params in URL
  // Router's useParams requires this to be a child of router and we need this to happen regardless of the route.
  const urlParams = new URLSearchParams(window.location.search);
  // @ts-ignore: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/keys
  const utmKeys = Array.from(urlParams.keys()).filter((k: string) =>
    k.startsWith("utm_")
  );
  // Set utm params in Redux so create cart request can send them to the backend
  if (utmKeys.length > 0) {
    const utmParams = utmKeys.reduce((acc, key) => {
      acc[key] = urlParams.get(key);
      return acc;
    }, {});
    reduxProps.setUtm(utmParams);
  }

  return (
    <Suspense fallback={<div />}>
      <RouterListener />
      <Router id="router">
        <CatalogueLayout path={root} {...props}>
          <Catalogue path="/" {...props} />
          <Catalogue path="/category/:category_uuid" {...props} />

          <Consumer path="/customer/email" {...props} />

          <CatalogueCart path="/cart" {...props} />
          <CatalogueQuantity path="/cart/quantity" {...props} />
          <CatalogueCartPayment path="/cart/payment" {...props} />
          <CheckoutReview path="/checkout/review" {...props} />
          <PaymentRequestReview path="/cart/review" {...props} />
          <ConsumerValidation path="/checkout/validation" {...props} />
          <PaymentResult path="/cart/result" {...props} />

          <CatalogueMoreInfo path="/more_info" {...props} />

          <CatalogueReviews path="/customers/reviews" {...props} />
          <CatalogueReviewForm
            path="/customers/review/:receipt_uuid/:rating"
            {...props}
          />

          <FulfillmentOptionsList path="/fulfillments/info" {...props} />
          <ConsumerDeliveryInfoForm path="/delivery/details" {...props} />
          <ConsumerDeliverySelection path="/delivery/selection" {...props} />

          <CatalogueDiscountForm path="/discount" {...props} />

          <ProductLink path="/p/:short_uuid" {...props} />
          <ProductQuestion path="/p/:short_uuid/questions" {...props} />

          <PaylinkPayment path="/payment" {...props} />

          <Paylink path="/c" {...props} />
          <Paylink path="/c/:amount" {...props} />

          <SubscriptionPrice path="/p/:short_uuid/prices" {...props} />

          {/* This needs to be below urls with /p/:short_uuid/:string otherwise would capture all */}
          <ProductLink path="/p/:short_uuid/:tracker" {...props} />
          {/* We place this last to ensure it doens't mess with other routes */}
          <Catalogue path="/:tracker" {...props} />
        </CatalogueLayout>

        <PaymentRequest path="/c/:short_uuid" />
        <SubscriptionCancellation
          path="/subscription_cancellations/:uuid"
          {...props}
        />

        <MerchantAdminDashboard path="/:username/admin" {...props} />
        <MerchantAdminProductForm path="/:username/admin/p/:uuid" {...props} />

        <PreviewScreen path="/:username/preview" {...props} />

        <NotFound default />
      </Router>
    </Suspense>
  );
}

const mapDispatchToProps = {
  setUtm: setUtm,
};

export default connect(null, mapDispatchToProps)(Root);
