import React from "react";
import Icon from "@mdi/react";
import { mdiArrowRight } from "@mdi/js";

export default function ShowMore({ onClick }: { onClick: () => void }) {
  return (
    <div
      className="flex items-center cursor-pointer text-gray-400 select-none"
      onClick={onClick}
    >
      <span className="text-sm mr-1">ver más</span>
      <Icon path={mdiArrowRight} className="h-6 w-6" />
    </div>
  );
}
