import React, { ReactNode } from "react";

import ReactDOM from "react-dom";
import { Platform } from "react-native";

import CuantoLoveFooter from "../../features/Catalogue/components/shared/CuantoLoveFooter";
import { tailwind } from "../../tailwind";

const isWeb = Platform.OS === "web";
const position = isWeb ? "fixed" : "absolute";

// generic box component with shadows and a footer
export default function BottomBox({ children }: { children: ReactNode }) {
  const rootEl = document.getElementById("root");

  if (!rootEl) return null;

  return ReactDOM.createPortal(
    <div className={`${position} w-ecommerce bottom-0 z-50`}>
      <div className="border-t border-white-300 bg-white pt-3 px-4 shadow-reverse">
        {children}
        <CuantoLoveFooter viewStyle={tailwind("py-2")} />
      </div>
    </div>,
    rootEl // Button must be used as children of the root app due to its positioning
  );
}
