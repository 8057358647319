import React from "react";

import { FlatList, TouchableOpacity } from "react-native";

import { hasInventory } from "../../../services/Product";
import { Product } from "../../../types/Product";
import CatalogueListItem from "./CatalogueListItem";
import CatalogueListItemButton from "./CatalogueListItemButton";

// this is equivalent to h-80, which is equivalent to 20rem or 320px
// use to optimize flatlist loading
const LIST_ITEM_HEIGHT_IN_PX = 320;

interface Props {
  products: Product[];
  productGroups: Object;
  storeClosed: boolean;
  numColumns: number;
  handleSelect: Function;
  handleAddToCart: Function;
}

const ProductsList = (props: Props) => {
  const {
    products,
    productGroups,
    storeClosed,
    numColumns,
    handleSelect,
    handleAddToCart,
  } = props;
  return (
    <div className="flex flex-wrap">
      <FlatList
        data={products}
        numColumns={numColumns}
        initialNumToRender={numColumns}
        key={numColumns}
        columnWrapperStyle={{ justifyContent: "space-evenly" }}
        keyExtractor={(item) => `${item.id}`}
        renderItem={({ item: product, index }) => {
          const isOutOfStock = !hasInventory(product, productGroups);

          return (
            <div
              data-testid={`cataloguelist-item-${index}`}
              // h-80 is equivalent to 320px, which is set as LIST_ITEM_HEIGHT_IN_PX
              // used to optimize Flatlist with getItemLayout.
              // if modified, must modify LIST_ITEM_HEIGHT_IN_PX
              className="flex flex-col justify-center items-center h-80"
            >
              <TouchableOpacity
                onPress={() => {
                  handleSelect(product);
                }}
              >
                <CatalogueListItem
                  product={product}
                  isOutOfStock={isOutOfStock}
                />
              </TouchableOpacity>
              <CatalogueListItemButton
                product={product}
                isOutOfStock={isOutOfStock}
                storeClosed={storeClosed}
                onClick={() => handleAddToCart(product)}
              />
            </div>
          );
        }}
        getItemLayout={(data, index) => ({
          length: LIST_ITEM_HEIGHT_IN_PX,
          offset: LIST_ITEM_HEIGHT_IN_PX * index,
          index,
        })}
      />
    </div>
  );
};

export default ProductsList;
