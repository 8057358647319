import React from "react";

import Icon from "@mdi/react";
import { mdiStorefront } from "@mdi/js";

import { resizeImageUrl } from "cuanto/web-app/utils";

export default function Avatar({ url }: { url: string }) {
  return (
    <div className="flex h-20 w-20 rounded-full overflow-hidden mr-2">
      {url ? (
        <img src={resizeImageUrl(url, { width: 300, height: 300 })} />
      ) : (
        <div className="bg-gray-200 w-full relative text-white">
          <Icon
            path={mdiStorefront}
            size={1.5}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      )}
    </div>
  );
}
