import React from "react";

import { connect, ConnectedProps } from "react-redux";
import { CatalogueState } from "cuanto/web-app/reducers";
import { navigate } from "cuanto/web-app/router";
import Avatar from "./Avatar";
import ShowMore from "./ShowMore";
import { AverageReviewStat, DeliveryStat, TransactionCountStat } from "./Stats";

type ReduxProps = ConnectedProps<typeof connector>;

function CatalogueHeader({
  merchant,
  totalTransactionCount,
  reviewsAverage,
  deliveryEnabled,
}: ReduxProps) {
  function navigateToShowMore() {
    navigate(merchant.username, `/more_info`);
  }

  // show more only if one of the tabs is active
  const showMoreEnabled = deliveryEnabled || reviewsAverage !== null;

  return (
    <div className="flex">
      <div className="self-center">
        <Avatar url={merchant.avatar_url} />
      </div>

      <div className="flex flex-col flex-grow justify-center">
        <div className="flex items-baseline">
          <span className="font-semibold text-xl">{merchant.full_name}</span>
          {showMoreEnabled && (
            <div className="ml-auto flex-shrink-0 pl-1 active:bg-gray-100 rounded-md">
              <ShowMore onClick={navigateToShowMore} />
            </div>
          )}
        </div>

        <div className="flex gap-x-1">
          <AverageReviewStat average={reviewsAverage} />
          <TransactionCountStat txnCount={totalTransactionCount} />
        </div>
        <DeliveryStat enabled={deliveryEnabled} />
      </div>
    </div>
  );
}

const mapStateToProps = ({ catalogue }: { catalogue: CatalogueState }) => ({
  merchant: catalogue.merchant,
  totalTransactionCount: catalogue.totalTransactionCount || 0,
  reviewsAverage: catalogue.reviewsAverage,
  deliveryEnabled: catalogue.hasFulfillmentOptions,
});

const connector = connect(mapStateToProps);
export default connector(CatalogueHeader);
