import React, { ComponentProps } from "react";

import Button from "../../web-app/components/Button.web";
import BottomBox from "./BottomBox";

// wrapper around button in a bottom box
export default function BottomButton(props: ComponentProps<typeof Button>) {
  return (
    <BottomBox>
      <Button {...props} />
    </BottomBox>
  );
}
