import styled from "styled-components/native";

import Colors from "../constants/Colors";
import Layout from "../constants/Layout";

export const Divider = styled.View`
  height: 1px;
  width: 100%;
  background: ${Colors.border.light.quaternary};

  margin-vertical: ${Layout.vSize(16)}px;
`;
