import { toString } from "cuanto/services/currency";
import { Currency } from "cuanto/types/Currency";

type Props = {
  price: number;
  priceNotDiscounted: number | null;
  currency: Currency;
  isDisabled?: boolean;
  classNames?: string;
};

export default function DiscountablePrice({
  price,
  priceNotDiscounted,
  currency,
  isDisabled,
  classNames = "",
}: Props) {
  const amount = toString({ amount: price, currency });

  const priceStyles = `${classNames} ${
    isDisabled ? "text-gray-400" : "text-gray-900"
  }`;

  if (!priceNotDiscounted || price >= priceNotDiscounted) {
    return <span className={priceStyles}>{amount}</span>;
  }

  const strikedStyle = `${classNames} line-through ${
    isDisabled ? "text-gray-400" : "text-gray-500"
  }`;

  return (
    <div className="flex flex-wrap">
      <span className={`${priceStyles} mr-2`}>{`${amount} `}</span>
      <span className={strikedStyle}>
        {toString({ amount: priceNotDiscounted, currency })}
      </span>
    </div>
  );
}
