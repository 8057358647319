// Functionality related to inventory feature. Feature is mostly contained
// in features/Products but these are functions used by the web app.
import { CuantoAPI } from "../api/CuantoAPI";
import { CartList } from "../features/Cart/types";
const api = new CuantoAPI();

type InventoryResponse = {
  not_available: string[];
  inventories: { uuid: string; quantity: number }[];
};

type CheckInventoryProps = {
  uuids: string[];
  cartList: CartList;
  // TODO: figure out type for these actions
  updateProductQuantity: any;
  removeFromCart: any;
};

// Depending on amount of items available, updates the consumer's cart and
// returns a message.
// See issue https://github.com/cabe56/cuanto/issues/278
export async function checkInventoryAndUpdateCart({
  uuids,
  cartList,
  updateProductQuantity,
  removeFromCart,
}: CheckInventoryProps) {
  if (!uuids || uuids.length == 0) return [];
  const { data }: { data: InventoryResponse } = await api.getInventory(uuids);
  const { not_available, inventories } = data;

  const removeMessages = not_available.map((uuid) => {
    const product = Object.values(cartList).find((p) => p.uuid === uuid);
    removeFromCart(product);
    return messageAboutAvailability(0, product.name);
  });

  const updateMessages = inventories
    .map(({ uuid, quantity }) => {
      const product = Object.values(cartList).find((p) => p.uuid === uuid);

      // quantity in cart greater than in stock
      if (product.quantity > quantity) {
        updateProductQuantity({ product, quantity });
        return messageAboutAvailability(quantity, product.name);
      }
    })
    .filter((m) => m);

  return [...removeMessages, ...updateMessages];
}

// Returns message used in alert to tell user there are only some or
// no items available.
function messageAboutAvailability(quantity: number, name: string): string {
  if (quantity >= 1) {
    const pluralizedWord = `disponible${quantity > 1 ? "s" : ""}`;
    return `Parece que solo hay ${quantity} ${name} ${pluralizedWord}.`;
  } else {
    return `Parece que no hay ${name}.`;
  }
}

export function getProductUuids(products) {
  return products
    .filter((p) => p.inventory && p.inventory.enabled)
    .map((p) => p.uuid);
}
