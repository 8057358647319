import { EcommerceView } from "../types/Product";
import { Product } from "./types";

export const selectProducts = (catalogueState) => catalogueState.products;
export const selectCategories = (catalogueState) => catalogueState.categories;

export const selectCategoryProducts = (catalogueState) => {
  const { products, selectedCategoryUuid } = catalogueState;

  const listedProducts = products.filter(
    (product) => product.ecommerce_view === EcommerceView.DEFAULT
  );

  return selectedCategoryUuid === "all"
    ? listedProducts
    : filterProducts(listedProducts, selectedCategoryUuid);
};

const filterProducts = (products: Product[], category: string): Product[] =>
  products.filter(({ categories }) =>
    categories.map(({ uuid }) => uuid).includes(category)
  );
